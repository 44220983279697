<template>
	<a-modal v-model="show" title="点评信息" :width="700" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<a-button v-if="is" type="primary" @click="addRow">添加点评信息</a-button>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>
		<a-table :scroll="{ x: '100%' }" :style="{ background: '#FFF' }" size="small" :columns="is ? columns : filcolumns"
						 ref="list" :data-source="data.records" :loading="loading" :bordered="false" :pagination="false" row-key="id">
			<template slot-scope="row" slot="content">
				<div v-html="row.content" @click="detailCon(row.content)"></div>
			</template>
			<!-- 附件 -->
			<template slot-scope="row" slot="attachment">
				<attachView :istitle="false" :attachment="row.attachment"></attachView>
			</template>
			<!--操作-->
			<template slot-scope="text, row, index" slot="action">
				<a-button-group shape="circle" size="small">
					<a-tooltip title="删除"><a-button icon="delete" @click="delRow(row, index)"></a-button></a-tooltip>
				</a-button-group>
			</template>
		</a-table>

		<a-modal :visible="visible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model>
				<a-form-model-item label="点评内容">
					<!-- <a-textarea v-model="formDate.content" placeholder="请输入点评内容"></a-textarea> -->
					<editor v-model="formDate.content" @input="e => (formData.content = e)"></editor>
				</a-form-model-item>
				<a-form-model-item>
					<attach-upload @input="e => (formDate.attachment = e)"></attach-upload>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<a-modal :visible="visible1" @ok="visible1 = false" @cancel="visible1 = false">
			<div v-html="ModalText"></div>
		</a-modal>
	</a-modal>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import selectStaff from '@/components/common/select-staff.vue';
import utils from '@/common/utils';
import request from '@/config/request';
import attachUpload from '@/components/common/attach-upload.vue';
import attachView from '@/components/common/attach-view.vue';
import editor from '@/components/component/editor.vue';
export default {
	components: { selectStaff, attachUpload, attachView, editor },

	data() {
		return {
			visible1: false,
			ModalText: '',
			show: false,
			loading: false,
			visible: false,
			is: false,
			organList: apiUtil.getOrganList(),
			data: [],
			editcontent: '',
			formDate: {
				content: '',
				attachment: [],
				planId: ''
			},
			staffId: apiUtil.getUser().staffId,
			planId: '',
			columns: [
				{ title: '点评人', dataIndex: 'staffName', width: 100, align: 'center', ellipsis: true },
				{ title: '点评内容', scopedSlots: { customRender: 'content' }, width: 160 },
				{ title: '附件', scopedSlots: { customRender: 'attachment' }, width: 200, align: 'left' },
				{ title: '操作', scopedSlots: { customRender: 'action' }, width: 80 }
			],
			filcolumns: [
				{ title: '点评人', dataIndex: 'staffName', width: 100, align: 'center', ellipsis: true },
				{ title: '点评内容', scopedSlots: { customRender: 'content' }, width: 200, align: 'center' },
				{ title: '附件', scopedSlots: { customRender: 'attachment' }, align: 'left' }
			]
		};
	},
	methods: {
		...apiUtil,
		detailCon(content) {
			this.ModalText = content;
			this.visible1 = true;
		},
		_show(row) {
			this.show = true;
			// 添加与删除按钮的控制
			this.is = false;
			// 有批注权限的才能添加批注和删除批注
			if (this.staffId) {
				if (row.scopeLeaderIds.find(item => item == this.staffId) != undefined) {
					this.is = true;
				}
			}
			this.planId = row.planId;

			this.getList();
		},
		// 获取数据
		getList() {
			this.loading = true;
			this.http
				.get(`/platform/work-plan/annotation/${this.planId}`)
				.then(ret => {
					if (ret.code == 200) {
						ret.data.records.forEach(item => (item.edit = false));
						this.data = ret.data;
						this.loading = false;
					}
				})
				.catch(err => {
					this.loading = false;
				});
		},
		doRefresh() {
			this.getList();
		},
		// 删除
		delRow(row, index) {
			console.log(row);
			if (!row.id) {
				this.data.records.splice(index, 1);
				return;
			}
			if (row.edit) {
				row.edit = false;
				row.content = this.editcontent;
				this.data.records.splice(index, 1, row);
				return;
			}
			utils.confirm('您确认要删除此分类吗?').then(() => {
				utils.showSpin();
				request.delete('/platform/work-plan/annotation/del/' + row.id).then(ret => {
					this.getList();
				});
			});
		},
		_close() {
			this.show = false;
			this.is = false;
		},
		// 点击添加
		addRow() {
			this.visible = true;
			this.formDate.content = '';
			this.formDate.attachment = [];
		},
		// 确定添加
		handleOk() {
			this.formDate.planId = this.planId;
			this.http
				.post('/platform/work-plan/annotation/save', this.formDate)
				.then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.visible = false;
							this.getList();
						});
					}
				})
				.catch(err => {
					this.visible = false;
					this.utils.error('操作失败！').then(() => {
						console.log(err);
					});
				});
		},
		handleCancel() {
			this.visible = false;
		},

		doSubmit() {
			this.show = false;
			this.is = false;
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}

.avatar-uploader>.ant-upload {
	width: 128px;
	height: 128px;
}

.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}
</style>
